import cookie from "react-cookies";
import { v4 as uuidv4 } from "uuid";

var Common = {
    CheckToken: () => {
        var token = cookie.load("access_token");
        if (token) {
            return true;
        }
        return false;
    },
    CheckRefreshToken: () => {
        var token = cookie.load("refresh_token");
        if (token) {
            return true;
        } else {
            return false;
        }
    },
    RemoveToken: () => {
        cookie.remove("access_token");
        cookie.remove("refresh_token");
        cookie.remove("must_add_referrer");
        cookie.remove("isCheckIn");
    },
    SaveDate: () => {
        const today = new Date();
        const date = today.getDate();
        cookie.save("date", date);
    },
    SaveToken: (data) => {
        cookie.save("access_token", data.access_token);
        cookie.save("refresh_token", data.refresh_token);
        cookie.save("user_phonenumber", data.user_phonenumber);
        Common.SaveDate();
    },
    CheckRole: () => {
        var role = cookie.load("role");
        if (role !== null && role !== undefined) {
            return role;
        } else {
            return null;
        }
    },
    CheckIsToDay: () => {
        const today = new Date();
        const date = today.getDate();
        return parseInt(cookie.load("date")) !== date;
    },
    SaveRefreshToken: (data) => {
        cookie.save("refresh_token", data.refresh_token);
        cookie.save("access_token", data.access_token);
        Common.SaveDate();
    },

    CheckLogin: () => {
        var token = cookie.load("access_token");
        var phoneNumber = cookie.load("user_phonenumber");
        if (token && phoneNumber) {
            return true;
        }
        return false;
    },
    GetRefreshToken: () => {
        return cookie.load("refresh_token");
    },

    PagingModel: {
        page: 1,
        size: 10,
    },

    renderColor(type) {
        switch (type) {
            case "noun":
                return "#FFCBCB";
            case "verb":
                return "#ACE1AF";
            case "adj":
                return "#FFCDEA";
            case "adverb":
                return "#E59BE9";
            case "pronoun":
                return "#B7C9F2";
            default:
                return "black";
        }
    },

    FormatDate(date) {
        return date.toLocaleDateString("vi-VN", {
            day: "2-digit",
            month: "2-digit",
        });
    },

    CheckDeviceType() {
        return "website";
    },
    RenderDeviceId() {
        return uuidv4();
    },
    RenderCatchBuster() {
        return new Date().getTime();
    },
    renderMessageError(data) {
        if (data.message) {
            switch (data.message) {
                case "user_phonenumber_invalid":
                    return "Số điện thoại không hợp lệ";
                case "code_invalid":
                    return "Mã xác thực không hợp lệ";
                case "account_password_not_match":
                    return "Mật khẩu không đúng";
                default:
                    return "Lỗi ";
            }
        }
        return false;
    },
    ConvertTimeStampToDate(timestamp) {
        var date = new Date(parseInt(timestamp, 10));
        var utcOffset = 7 * 60 * 60 * 1000;
        var localDate = new Date(date.getTime() + utcOffset);
        var hours = String(localDate.getUTCHours()).padStart(2, "0");
        var minutes = String(localDate.getUTCMinutes()).padStart(2, "0");
        var day = String(localDate.getUTCDate()).padStart(2, "0");
        var month = String(localDate.getUTCMonth() + 1).padStart(2, "0");
        var year = localDate.getUTCFullYear();
        var formattedTime = `${hours}:${minutes}`;
        var formattedDate = `${day}/${month}/${year}`;
        var formattedDateTime = `${formattedTime} ${formattedDate}`;
        return formattedDateTime;
    },
};

export default Common;
